import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown"
import Moment from "react-moment"
import Prism from "prismjs"

import Layout from "../components/layout"

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiArticle(slug: { eq: $slug }) {
      slug
      title
      content
      published_at
      image {
        childImageSharp {
          fluid(maxWidth: 780, quality: 80, toFormat: JPG) {
            src
          }
        }
      }
    }
  }
`

const createMarkup = value => {
  return {__html: value};
}

const Code = props => {
  const {language, value} = props;
  if (language === 'uikit') {
    return (
      <div dangerouslySetInnerHTML={createMarkup(value)} />
    );
  } else {
    return (
      <pre lang={language}><code className={`language-${language} code-overflow`}>{value}</code></pre>
    );
  }
}

const activatePrism = () => {
  setTimeout(() => Prism.highlightAll(), 0);
}

class Article extends Component {

  constructor({ data, location}) {
    super();
    this.state = {
      data,
      location
    };
  }

  componentDidMount() {
    activatePrism();
  }
  
  render () {
    const article = this.state.data.strapiArticle;
    const location = this.state.location;
    const renderers = {
      code:  Code
    };
    return (
      <Layout location={location}>
        <div>
          <div
            id="banner"
            className="uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light uk-padding uk-margin"
            data-src={article.image.childImageSharp.fluid.src}
            data-srcset={article.image.childImageSharp.fluid.src}
            data-uk-img
          >
            <h1 className="uk-text-center">{article.title}</h1>
          </div>

          <div className="uk-container uk-container-small">
            <ReactMarkdown 
              renderers={renderers}
              className="articleContent"
              source={article.content}
              escapeHtml={false}
              transformImageUri={uri => 
                uri.startsWith('http') 
                  ? uri 
                  : `${process.env.IMAGE_BASE_URL}${uri}`
              }  
            />
            <p>
              <Moment format="MMM Do YYYY">{article.published_at}</Moment>
            </p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Article

Article.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};